<template>
  <div>
    <v-btn
        color="primary"
        type="submit"
        :loading="state.isProcessing"
        :disabled="state.isProcessing || state.isDisabled || state.isDeleting || !state.isValid || submitDisabled"
    >
      <v-icon left>mdi-check</v-icon>
      {{ submitName }}
    </v-btn>
    <v-btn
        v-if="deletable"
        class="ms-3"
        @click="onDelete"
        :loading="state.isDeleting"
        :disabled="state.isProcessing || state.isDisabled || state.isDeleting || deleteDisabled"
    >
      <v-icon left>mdi-delete-outline</v-icon>
      {{ $t('btn.delete') }}
    </v-btn>
    <v-btn
        class="ms-3"
        @click="onCancel ? onCancel() : $router.go(-1)"
    >
      <v-icon left>mdi-close</v-icon>
      {{ $t('btn.cancel') }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import {LocaleMessages, TranslateResult} from "vue-i18n";

export default Vue.extend({
  data: () => ({
  }),
  props: {
    // injected properties must be listed here explicitly, Vue2+TS doesn't work well with DI
    // To keep reactivity, we need to pass an object (not just a simple value)
    // (nice side effect: we get full type safety)
    // Idea of this workaround based on https://github.com/vuejs/vue/issues/8969#issuecomment-955200201
    state: {
      type: Object as () => {
        readonly isDisabled: boolean,
        readonly isValid: boolean,
        readonly isProcessing: boolean,
        readonly isDeleting: boolean
      },
      required: false
    },
    deletable: {
      type: Boolean,
      required: false,
      default: false
    },
    deleteDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    submitDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    onCancel: {
      type: Function,
      required: false
    },
    onDelete: {
      type: Function,
      required: false
    },
    submitBtnName: {
      type: String,

    }
  },
  inject: ['state', 'onCancel', 'onDelete'],
  computed: {
    submitName(): TranslateResult | LocaleMessages {
      if (this.submitBtnName === undefined) return this.$t('btn.save');
      else return this.$t(this.submitBtnName);
    }
  }
})
</script>