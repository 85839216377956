<template>
  <v-container
      fluid
      class="fill-height lc-container-login"
  >
    <v-row>
      <v-col>
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'mlc-login'
});
</script>

<style>
.lc-container-login {
  background-color:#aaaaaa;
  background-image:url('../../../public/bg-login.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>