import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import MLC from "@/modules/MLC";
import VueColcade from "@/modules/vue-colcade"

Vue.use(VueColcade);

Vue.config.devtools = false;
Vue.config.productionTip = false;

MLC.init({id: '', prefix: ''});
Vue.prototype.$MLC = MLC;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
