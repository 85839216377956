<template>
  <div>
    <v-dialog
        v-model="value"
        max-width="500px"
        @click:outside="() => {$emit('cancel-dialog')}"
    >
      <v-card>
        <mlc-form
            :form="frm"
            :state="state"
            :on-cancel="() => {$emit('cancel-dialog')}"
        >
          <v-card-title class="primaryBackground">{{ title }}</v-card-title>
          <v-card-text>
            <v-divider/>
            <div v-if="text !== undefined">
              {{ text }}
            </div>
            <table class="lcTable">
              <tbody>
              <tr
                  v-for="field in inputFields.fields"
                  :key="field.vModel.name"
              >
                <th>{{field.label}}</th>
                <td>
                  <div v-if="field.type === 'text'">
                    <v-text-field
                        :id="inputFields.identifier + field.vModel.name"
                        v-model="field.vModel.value"
                        autocapitalize="off"
                        spellcheck="false"
                        autocorrect="off"
                        hide-details="auto"
                        dense
                        type="text"
                        :rules="frm.check(field.required, { id: inputFields.identifier + field.vModel.name })"
                        :error-messages="errors[field.vModel.name]"
                        @input="frm.reset(field.vModel.name)"
                    />
                  </div>
                  <div v-else-if="field.type === 'password'">
                    <v-text-field
                        :id="inputFields.identifier + field.vModel.name"
                        v-model="field.vModel.value"
                        :type="field.vModel.show ? 'text' : 'password'"
                        autocapitalize="off"
                        spellcheck="false"
                        autocorrect="off"
                        autocomplete="new-password"
                        hide-details="auto"
                        dense
                        counter="40"
                        maxlength="40"
                        :rules="frm.check(field.required, { id: inputFields.identifier + field.vModel.name })"
                        :error-messages="errors[field.vModel.name]"
                        @input="frm.reset(field.vModel.name)"
                    >
                      <template v-slot:append>
                        <!--field.vModel.value = -->
                        <v-icon class="me-2" @click="field.vModel.value = suggestPassword(field.vModel.name)">mdi-auto-fix</v-icon>
                        <v-icon @click="field.vModel.show = !field.vModel.show">{{ field.vModel.show ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div v-else-if="field.type === 'checkbox'">
                    <v-checkbox
                        :id="inputFields.identifier + field.vModel.name"
                        v-model="field.vModel.value"
                        hide-details="auto"
                        dense
                        :rules="frm.check(field.required, { id: inputFields.identifier + field.vModel.name })"
                        :error-messages="errors[field.vModel.name]"
                        @input="frm.reset(field.vModel.name)"
                    />
                  </div>
                  <div v-else>
                    <v-select
                        :id="inputFields.identifier + field.vModel.name"
                        v-model="field.vModel.value"
                        :items="field.items"
                        hide-details="auto"
                        dense
                        :rules="frm.check(field.required, { id: inputFields.identifier + field.vModel.name })"
                        :error-messages="errors[field.vModel.name]"
                        @input="frm.reset(field.vModel.name)"
                    />
                  </div>
                  <div
                      v-if="field.description"
                      class="small"
                  >
                    {{field.description}}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <mlc-form-buttons
                :submit-btn-name="submitBtnName"
            />
          </v-card-actions>
        </mlc-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {LocaleMessages, TranslateResult} from "vue-i18n";
import MlcForm, {Form, FormMode} from "@/components/MlcForm.vue";
import MlcFormButtons from "@/components/MlcFormButtons.vue";
import MLC from "@/modules/MLC";

type InputField = {type: InputFieldType, vModel: {name: string, value: any, show?: boolean}, items?: Array<any>, label: string | TranslateResult | LocaleMessages, required: boolean, description?: string};
type InputFieldType = 'text' | 'password' | 'checkbox' | 'select';

export default Vue.extend({
  name: "mlc-frm-dialog",
  components: {MlcFormButtons, MlcForm},
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String
    },
    inputFields: {
      type: Object as () => {identifier: string, fields: Array<InputField>},
      required: true
    },
    onCreateFrm: {
      type: Object as () => {url: string, frmMode: string, keys?: Array<string>},
      required: true
    },
    submitBtnName: {
      type: String
    }
  },
  data: function() {
    return {
      frm: null as null | Form,
      state: {
        isDisabled: false,
        isValid: true,
        isProcessing: false
      },
      errors: {} as {[key:string]: string},
      password: '',
      showPassword: false
    }
  },
  created() {
    this.frm = new Form({
      url: this.onCreateFrm.url,
      data: this,
      mode: this.onCreateFrm.frmMode === 'New' ? FormMode.New : FormMode.Edit,
      serialize: () => {
        const ret = {} as { [key: string]: string };
        // not all input fields are needed in serialize
        if (this.onCreateFrm.keys !== undefined) {
          this.onCreateFrm.keys.forEach((el: string) => {
            const tmp = this.inputFields.fields.find((field: InputField) => field.vModel.name === el)
            if (tmp !== undefined) ret[el] = tmp.vModel.value;
          })
        } else {
          // all inputFields are needed in serialize
          this.inputFields.fields.forEach((el: InputField) => {
            ret[el.vModel.name] = el.vModel.value;
          })
        }
        console.log(ret);
        return ret;
      }
    });
  },
  methods: {
    async suggestPassword(identifier: string) {
      const field = this.inputFields.fields.find((field: InputField) => {
        if (field.vModel.name === identifier) {
          return field;
        }
      });
      // await cant be used inside 'find'-function
      if (field) field.vModel.value = await MLC.suggestPassword();
    },
  }
})
</script>