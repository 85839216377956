<template>
  <div>
    <!--(( Gerüst, Navigation ))-->
    <v-app-bar
    >
      <v-app-bar-title>##MyLiveConfig##</v-app-bar-title>
      <template v-slot:[`extension`]>
        <v-tabs>
          <v-tab>##DASHBOARD##</v-tab>
          <v-tab>##CONTRACTS##</v-tab>
          <v-tab>##INVOICES##</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <router-view/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'mlc-base'
});

</script>