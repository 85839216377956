// Lightweight Vue masonry with "Colcade":
// https://github.com/alexiscolin/vue-colcade
// Modified to keep initial item order

import Colcade from 'colcade';

const ColcadeWrapper = function $ColcadeWrapper(obj) {
  this.data = obj;

  this.init = () => {
    let elGrid = document.querySelector(this.data.el)
    if (elGrid && this.data.config.items) {
      let elItems = makeArray(elGrid.querySelectorAll(this.data.config.items))
      let max=1;
      // find out next gridOrder value
      elItems.forEach(item => { if (item.dataset.gridOrder && parseInt(item.dataset.gridOrder) >= max) max=parseInt(item.dataset.gridOrder)+1; } )
      // assign gridOrder attribute to all new elements
      elItems.forEach(item => { if (!item.dataset.gridOrder) item.dataset.gridOrder = max++; })
      // sort by gridOrder
      elItems.sort(function(a,b) { return parseInt(a.dataset.gridOrder) === parseInt(b.dataset.gridOrder) ? 0 : (parseInt(a.dataset.gridOrder) > parseInt(b.dataset.gridOrder) ? 1 : -1) })
      // apply order to DOM elements
      for (let idx=0; idx < elItems.length; idx++) {
        elGrid.appendChild(elItems[idx])
      }
    }

    this.colc = this.createGrid();
  };
  this.createGrid = () => new Colcade(this.data.el, this.data.config);
  this.appendGrid = item => this.colc.append(item);
  this.prependGrid = item => this.colc.prepend(item);
  this.destroyGrid = () => new Promise((resolve) => {
    this.colc.destroy();
    this.colc = null;
    delete this.colc;
    resolve();
  });

  this.init();
};

const ColcadeFactory = function $ColcadeFactory() {
  // do nothing
};
ColcadeFactory.prototype = {
  create: function $create(obj) {
    this[obj.name] = !this[obj.name] && new ColcadeWrapper(obj);
  },
  destroy: function $destroy(name) {
    if (Object.prototype.hasOwnProperty.call(this, name)) {
      this[name].destroyGrid();
      this[name] = null;
      delete this[name];
    } else {
      throw new Error(`${name} is not a property of $colcade`);
    }
  },
  update: function $update(name) {
    if (Object.prototype.hasOwnProperty.call(this, name)) {
      this[name].destroyGrid().then(() => {
        this[name].init();
      });
    } else {
      throw new Error(`${name} is not a property of $colcade`);
    }
  },
};

const VueColcade = {
  install: function $install(Vue) {
    Vue.prototype.$colcade = new ColcadeFactory();
  },
};

// turn element or nodeList into an array
// (copied from colcade.js)
function makeArray( obj ) {
  var ary = [];
  if ( Array.isArray( obj ) ) {
    // use object if already an array
    ary = obj;
  } else if ( obj && typeof obj.length == 'number' ) {
    // convert nodeList to array
    for ( var i=0; i < obj.length; i++ ) {
      ary.push( obj[i] );
    }
  } else {
    // array of single index
    ary.push( obj );
  }
  return ary;
}

export default VueColcade;
